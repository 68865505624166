import PageContext from '@context'
import {
  getCurrentDateAndTimeInUTC,
  getFormattedDateTime,
  multilinkToUrl,
} from '@helpers'
import useNavHeight from '@hooks/use-nav-height'
import useStringTranslation from '@hooks/use-string-translation'
import {
  Box,
  Button,
  CssBaseline,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import {
  ContentContainer,
  EventTimer,
  H3,
  H6,
  Icon,
  Img,
  MarkdownHandler,
  PageContainer,
  TextSm,
} from '@system'
import ACC_THEME from '@themes'
import { imgMaxWidths } from '@variables/global-variables'
import classNames from 'classnames'
import InnerHTML from 'dangerously-set-html-content'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import { Link as GatsbyLink } from 'gatsby'
import get from 'lodash/get'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import SbEditable from 'storyblok-react'
import Layout from '../layout'
import GoogleStructuredData from './google-structured-data'
import WistiaVideoModule from './wistia-video-module'

dayjs.extend(utc)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const useStyles = makeStyles({
  headerImage: {
    height: '66vh',
    maxHeight: '600px',
    objectPosition: 'top',
    width: '100%',
    '& img': {
      objectFit: 'cover',
    },
  },
  body: ({ headerImage, headerSpacing, image, hideHeaderImage }) => ({
    marginTop:
      headerImage && !hideHeaderImage
        ? headerSpacing === 'high'
          ? '-470px'
          : headerSpacing === 'low'
          ? '-125px'
          : '-280px'
        : null,
    paddingTop:
      headerImage && !hideHeaderImage
        ? image.length > 0
          ? '140px'
          : '135px'
        : null,
    position: 'relative',
    [ACC_THEME.breakpoints.down('md')]: {
      padding: headerImage && !hideHeaderImage ? '20px 20px' : null,
    },
    [ACC_THEME.breakpoints.down('sm')]: {
      paddingTop: headerImage && !hideHeaderImage ? '90px !important' : null,
    },
  }),
  columnsContainer: ({ headerImage, hideHeaderImage }) => ({
    margin: headerImage && !hideHeaderImage ? '-80px auto 0 auto' : '0 auto',
    paddingBottom: '60px',
    paddingTop: '60px',
    '& .wistia_embed': {
      width: '100% !important',
    },
    '& form': {
      backgroundColor: ACC_THEME.palette.background.slite,
      padding: '16px',
      '& button': {
        width: '100%',
      },
    },
    [ACC_THEME.breakpoints.down('xs')]: {
      paddingBottom: '35px',
      paddingTop: '35px',
      '& form': {
        '& h4': {
          padding: '0px 10px',
          textAlign: 'center',
        },
        '& > div > div': {
          width: '100%',
        },
      },
    },
  }),
  columns: {
    display: 'grid',
    gap: '32px',
    [ACC_THEME.breakpoints.up('lg')]: {
      gridTemplateColumns: '60% 1fr',
      gridTemplateRows: 'auto 1fr',
      rowGap: 'unset',
    },
  },
  columnLeft: {
    [ACC_THEME.breakpoints.up('lg')]: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
    },
  },
  columnRight: {
    [ACC_THEME.breakpoints.up('lg')]: {
      gridColumnStart: 2,
      gridColumnEnd: 3,
    },
  },
  formContainer: {
    gridRowStart: 2,
    gridRowEnd: 3,
    [ACC_THEME.breakpoints.up('lg')]: {
      gridRowStart: 1,
      gridRowEnd: 3,
    },
  },
  formSticky: {
    [ACC_THEME.breakpoints.up('lg')]: {
      position: 'sticky',
      top: ({ navHeight }) => `${navHeight + 16}px`,
    },
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '16px',
    [ACC_THEME.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  startDate: {
    alignItems: 'center',
    color: ACC_THEME.palette.text.tertiary,
    display: 'flex',
    fontSize: '20px',
    fontWeight: ACC_THEME.typography.fontWeightMedium,
    gap: '8px',
    margin: '0',
  },
  title: ({ isRelatedContent, isVideoModule, shouldShowVideo }) => ({
    color: ACC_THEME.palette.primary.main,
    display: 'flex',
    fontWeight: '800',
    letterSpacing: '-0.18px',
    lineHeight: '1.2',
    maxWidth:
      (shouldShowVideo && !isVideoModule) || !isRelatedContent
        ? '100%'
        : '784px',
  }),
  subtitle: ({ calculatedWidth }) => ({
    display: 'block',
    fontSize: '24px',
    fontWeight: ACC_THEME.typography.fontWeightRegular,
    lineHeight: '1.4',
    maxWidth: calculatedWidth === '100%' ? '100%' : '784px',
    [ACC_THEME.breakpoints.up('lg')]: {
      fontSize: '26px',
    },
  }),
  description: ({ calculatedWidth }) => ({
    color: ACC_THEME.palette.primary.main,
    display: 'block',
    fontSize: '18px',
    lineHeight: '1.6',
    marginTop: '24px',
    maxWidth: calculatedWidth === '100%' ? '100%' : '628px',
    '& p': {
      fontSize: '18px',
    },
    '& > p': {
      display: 'block',
      lineHeight: '1.6',
      margin: '16px 0',
    },
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '18px',
      gap: '6px',
      lineHeight: '1.5',
    },
    [ACC_THEME.breakpoints.up('md')]: {
      fontSize: '20px',
      '& p': {
        fontSize: '20px',
      },
      '& ul': {
        fontSize: '20px',
      },
    },
  }),
  infoGraphicImageContainer: {
    textAlign: 'center',
    height: 'auto',
    '& img': {
      width: '100%',
      objectFit: 'contain',
    },
    [ACC_THEME.breakpoints.up('sm')]: {
      marginTop: '50px',
    },
  },
  infoGraphicImage: {
    width: '100%',
    objectFit: 'cover',
    maxWidth: '199px',
    [ACC_THEME.breakpoints.down('sm')]: {
      marginTop: '0 !important',
    },
  },
  videoPreviewContainer: {
    marginTop: '32px',
    [ACC_THEME.breakpoints.down('sm')]: {
      marginTop: 'unset',
      borderBottom: `2px solid ${ACC_THEME.palette.border.gray}`,
      borderTop: `2px solid ${ACC_THEME.palette.border.gray}`,
      marginBottom: '32px',
      padding: '64px 0',
    },
  },
  speakers: {
    display: 'grid',
    gap: '45px',
    justifyContent: 'center',
    [ACC_THEME.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
    [ACC_THEME.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  speakersTitle: {
    color: ACC_THEME.palette.text.primary,
    fontSize: '24px',
    fontWeight: ACC_THEME.typography.fontWeightRegular,
    margin: '32px 0',
    textAlign: 'center',
    [ACC_THEME.breakpoints.up('sm')]: {
      marginTop: '16px 0',
      textAlign: 'left',
    },
    [ACC_THEME.breakpoints.up('lg')]: {
      fontSize: '26px',
    },
  },
  buttonLink: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block',
    margin: '0 auto',
    padding: '11px 14px',
    fontWeight: ACC_THEME.typography.fontWeightBold,
    color: ACC_THEME.palette.common.white,
    backgroundColor: ACC_THEME.palette.primary.main,
    '&:hover': {
      backgroundColor: ACC_THEME.palette.primary.main,
    },
  },
  markDownText: ({ videoPreviewWistiaId }) => ({
    marginTop: '60px',
    marginBottom: videoPreviewWistiaId?.length > 0 && '60px',
    fontSize: '18px',
    lineHeight: '28px',
    color: ACC_THEME.palette.text.tertiary,
    '& h4': {
      color: `${ACC_THEME.palette.text.tertiary} !important`,
    },
    '& p': {
      fontSize: '18px',
    },
    '& ul': {
      marginTop: '16px',
      '& li': {
        fontSize: '18px',
        '&:before': {
          color: ACC_THEME.palette.secondary.main,
        },
      },
    },
    [ACC_THEME.breakpoints.up('md')]: {
      fontSize: '20px',
      '& p': {
        fontSize: '20px',
      },
      '& ul li': {
        fontSize: '20px',
      },
    },
  }),
  formatTitle: {
    color: ACC_THEME.palette.primary.main,
    display: 'flex',
    fontWeight: ACC_THEME.typography.fontWeightMedium,
    marginBottom: '24px',
    textAlign: 'left',
  },
  breadcrumbLink: {
    textDecoration: 'none',
    color: ACC_THEME.palette.primary.main,
    '& h5': {
      fontWeight: ACC_THEME.typography.fontWeightBold,
    },
  },
  returnLink: {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    '& h6': {
      fontSize: '1rem',
      fontWeight: ACC_THEME.typography.fontWeightBold,
      lineHeight: 1,
    },
  },
  returnLinkIcon: {
    height: '14px',
    marginLeft: '-4px',
    width: '14px',
  },
  player: {
    padding: '10px 0px',
    '& iframe': {
      filter: 'hue-rotate(344deg) brightness(120%)',
    },
    '& div': {
      backgroundColor: `${ACC_THEME.palette.background.whiteSmoke} !important`,
    },
  },
  webinarVideoTitle: {
    padding: '20px 0px',
    color: ACC_THEME.palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: ACC_THEME.typography.fontWeightBold,
    [ACC_THEME.breakpoints.down('sm')]: {
      fontSize: '1rem',
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  summaryLinks: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    listStyleType: 'none',
    marginBottom: '24px',
    padding: '0',
  },
  summaryLink: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  summaryLinkTitle: {
    color: ACC_THEME.palette.text.iris,
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: ACC_THEME.typography.fontWeightBold,
  },
})

const VideoPreview = ({
  assetGraphic,
  classes,
  imageAltText,
  webinarVideoExists,
  videoPreviewTitle,
  webinarPreviewBlok,
  setWistiaRef,
}) => {
  return (
    <>
      {webinarVideoExists && (
        <Box className={classes.videoPreviewContainer}>
          <Typography className={classes.webinarVideoTitle}>
            {videoPreviewTitle}
          </Typography>
          <WistiaVideoModule
            blok={webinarPreviewBlok}
            setWistiaRef={setWistiaRef}
          />
        </Box>
      )}
    </>
  )
}

const ResourceDetail = ({ blok, marketoForm }) => {
  const {
    image,
    assetGraphic,
    assetToDownload,
    title,
    subtitle,
    description,
    buttonAssetUrl,
    workflow,
    companyType,
    format,
    startDate,
    duration,
    preBody,
    body,
    speakers,
    relatedContent,
    _uid,
    isUngated,
    metaData,
    product,
    player,
    customForm,
    canonicalPage,
    noIndex,
    conversionType,
    gatingOverride,
    headerSpacing,
    videoPreviewTitle,
    videoPreviewWistiaId,
    isStickyForm,
    isWebinarSeries,
    webinarSeriesEvents,
    hideHeaderImage,
  } = blok

  const isWebinarsModule = format === 'Webinar'
  const isVideoModule = format === 'Video'
  const variant = 'resource-center'

  const isRelatedContent = !!relatedContent && relatedContent.length > 0

  const [loadMarketoForm, setLoadMarketoForm] = useState(false)
  const [isFormSubmitted, setFormSubmission] = useState(false)
  const [isFormVisible, setFormVisibility] = useState(true)
  const [label, setLabel] = useState('On Demand')
  const navHeight = useNavHeight()

  const handleDesktop = useMediaQuery(ACC_THEME.breakpoints.up('lg'))

  const speakerCards = speakers
  const stringToGating = () => {
    switch (gatingOverride) {
      case 'gated':
        return false
      case 'ungated':
        return true
      default:
        return isUngated
    }
  }

  const assetIsUngated = gatingOverride ? stringToGating() : isUngated

  const imageSrc =
    !!assetGraphic &&
    assetGraphic.split('/')[assetGraphic.split('/').length - 1]
  const imageAltText =
    !!imageSrc && imageSrc.substr(0, imageSrc.lastIndexOf('.'))

  const headerImage = get(image, '[0].image')

  const { first_published_at, resourceForm, isInEditor } =
    useContext(PageContext)

  const [wistiaRef, setWistiaRef] = useState(null)

  const hasResources = isVideoModule

  const webinarVideoExists = !!videoPreviewWistiaId

  const shouldShowVideo = assetIsUngated && webinarVideoExists
  const isOnDemandButtonVisible = !!isWebinarsModule && webinarVideoExists

  const shouldContainHalfWidth =
    (webinarVideoExists && assetIsUngated) ||
    ((!!assetGraphic || isRelatedContent) && hasResources)

  const calculatedWidth = shouldContainHalfWidth
    ? '65%'
    : shouldShowVideo
    ? '100%'
    : '65%'

  const hiddenFieldsForForm = [
    {
      marketoId: 'zimportLastAsset',
      value: title,
    },
    {
      marketoId: 'assetWorkflow',
      value: workflow,
    },
    {
      marketoId: 'Firm_Type__c_lead',
      value: companyType,
    },
    {
      marketoId: 'product',
      value: product,
    },
    {
      marketoId: 'format',
      value: format,
    },
  ]

  const getLocalMinutesFromTime = get(
    getFormattedDateTime(startDate, 'hh:mm').split(':'),
    '[1]'
  )

  const webinarLaunchTime =
    getLocalMinutesFromTime === '00'
      ? getFormattedDateTime(startDate, 'MMMM D YYYY, ha z')
      : getFormattedDateTime(
          startDate,
          webinarVideoExists ? 'MMMM D YYYY, ha z' : 'MMMM D YYYY, h:mma z'
        )

  // Localized strings
  const liveString = useStringTranslation('Live')
  const comingSoonString = useStringTranslation('Coming Soon')
  const watchNowString = useStringTranslation('Watch Now')
  const registerString = useStringTranslation('Register')
  const formatString = useStringTranslation(format || '')
  const onDemandString = useStringTranslation('On Demand')
  const upcomingString = useStringTranslation('Upcoming')
  const backToWebinarString = useStringTranslation('Back to Webinar Hub')

  const isWebinarLive = label === liveString
  const isComingSoonWebinar = label === comingSoonString
  const isUpcomingWebinar = label === upcomingString
  const isOnDemandWebinar = label === onDemandString
  const context = useContext(PageContext)
  const succesConversionType =
    !!context.location &&
    context.location.state &&
    context.location.state.conversionType

  useEffect(() => {
    if (succesConversionType && !isInEditor) {
      window.mutiny = window.mutiny || []
      window.mutiny?.client?.trackConversion({ name: 'resource-detail' })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'page.content',
        'page.content': {
          v: 11,
          type: 'paid conversion',
          convType: succesConversionType || conversionType || 'generic',
          id: _uid,
          name: metaData.title,
          url: document.URL,
        },
      })
    }
    if (
      (webinarVideoExists && !assetIsUngated && isWebinarsModule) ||
      hasResources
    ) {
      // setFormVisibility(false)
    }
    setLoadMarketoForm(true)
    getWebinarStatus()
  }, [])

  // TODO: Consolidate all timing and messaging around new state

  const submitButtonText =
    !!isWebinarsModule && !assetIsUngated && webinarVideoExists
      ? watchNowString
      : isUpcomingWebinar
      ? registerString
      : null

  const classes = useStyles({
    calculatedWidth,
    headerImage,
    headerSpacing,
    image,
    videoPreviewWistiaId,
    shouldShowVideo: shouldShowVideo,
    isVideoModule: isVideoModule,
    isRelatedContent: isRelatedContent,
    label: label,
    webinarVideoExists: webinarVideoExists,
    isFormVisible: isFormVisible,
    isWebinarLive: isWebinarLive,
    isComingSoonWebinar: isComingSoonWebinar,
    isOnDemandWebinar: isOnDemandWebinar,
    isUngated: assetIsUngated,
    navHeight,
    hideHeaderImage,
  })

  const splitDateTime = (dateTime) => {
    const splittedDate = !!dateTime && get(dateTime.split(' '), '[0]')
    const splittedTime = !!dateTime && get(dateTime.split(' '), '[1]')

    return {
      date: splittedDate,
      time: splittedTime,
    }
  }

  const { date: webinarStartDate, time: webinarStartTime } =
    splitDateTime(startDate)

  const currentDateAndTime = getCurrentDateAndTimeInUTC()
  const getWebinarStatus = () => {
    const { date: currentDate, time: currentTime } =
      splitDateTime(currentDateAndTime)
    const currentTimeInMinutes =
      currentTime.split(':')[0] * 60 + parseInt(currentTime.split(':')[1])

    const webinarStartTimeInMinutes =
      !!webinarStartTime &&
      webinarStartTime.split(':')[0] * 60 +
        parseInt(webinarStartTime.split(':')[1])

    const webinarEndTimeInMinutes =
      webinarStartTimeInMinutes + parseInt(duration)

    if (
      currentDate === webinarStartDate &&
      currentTimeInMinutes >= webinarStartTimeInMinutes &&
      currentTimeInMinutes <= webinarEndTimeInMinutes
    )
      return setLabel(liveString)

    if (dayjs(startDate).isSameOrAfter(currentDateAndTime))
      return setLabel(upcomingString)

    if (
      dayjs(startDate).isSameOrBefore(currentDateAndTime) &&
      !webinarVideoExists
    )
      return setLabel(comingSoonString)
  }

  const markdownDescription = get(preBody, '[0].markdown.[0].markdown')

  let formatTitle = formatString.toUpperCase()

  if (isUpcomingWebinar) {
    formatTitle = `${upcomingString.toUpperCase()} ${formatString.toUpperCase()}`
  }

  const webinarData = {
    publishedAt: first_published_at,
    ...blok,
  }

  const webinarPreviewBlok = {
    component: 'wistiaVideoModule',
    hasContainer: false,
    isGatedVideo: false,
    title: videoPreviewTitle,
    wistiaVideoId: videoPreviewWistiaId,
  }

  const numberedWebinarSeriesEvents = useMemo(() => {
    if (isWebinarSeries && webinarSeriesEvents?.length > 1) {
      webinarSeriesEvents
        .filter((blok) => blok.component === 'webinarSeriesEvent')
        .forEach((event, index) => (event.eventNo = index + 1))
      return webinarSeriesEvents
    }
    return null
  }, [isWebinarSeries, webinarSeriesEvents])

  const renderSummaryLinks = () => (
    <ul className={classes.summaryLinks}>
      {numberedWebinarSeriesEvents.map(
        ({ _uid, startDate, title, eventNo }) => (
          <li className={classes.summaryLink} key={`event-${_uid}`}>
            {!!title && (
              <a
                href={`#event-${eventNo}`}
                className={classes.summaryLinkTitle}
              >
                {title}
              </a>
            )}
            {!!startDate && (
              <span className={classes.dateContainer}>
                <span className={classes.startDate}>
                  <Icon>Calendar</Icon>
                  {getFormattedDateTime(startDate, 'MMMM D YYYY, h:mma z')}
                </span>
              </span>
            )}
          </li>
        )
      )}
    </ul>
  )

  return (
    <Layout metaData={metaData} canonicalPage={canonicalPage} noIndex={noIndex}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={blok}>
          {isWebinarsModule && (
            <GoogleStructuredData type="Webinar" data={webinarData} />
          )}
          <Box>
            {headerImage && !hideHeaderImage && (
              <Img
                className={classes.headerImage}
                doNotScale={true}
                src={headerImage}
                maxWidth={imgMaxWidths.headerImage}
              />
            )}
            <Box className={classes.body}>
              <PageContainer>
                <ContentContainer className={classes.columnsContainer}>
                  {formatTitle && (
                    <Box className={classes.formatTitle}>
                      {isWebinarsModule ? (
                        <GatsbyLink
                          to={multilinkToUrl(
                            {
                              story: { url: '/webinars' },
                              linktype: 'story',
                            },
                            isInEditor
                          )}
                          className={classNames(
                            classes.breadcrumbLink,
                            classes.returnLink
                          )}
                        >
                          <Icon styles={classes.returnLinkIcon}>
                            ChevronLeft
                          </Icon>
                          <H6>{backToWebinarString}</H6>
                        </GatsbyLink>
                      ) : (
                        <TextSm>{formatTitle}</TextSm>
                      )}
                    </Box>
                  )}
                  <div className={classes.columns}>
                    <div className={classes.columnLeft}>
                      {player && (
                        <InnerHTML html={player} className={classes.player} />
                      )}
                      {title && (
                        <Box>
                          <H3 component="h1" className={classes.title}>
                            {title}
                          </H3>
                        </Box>
                      )}
                      {isWebinarsModule &&
                        startDate &&
                        !isWebinarSeries &&
                        webinarSeriesEvents?.length < 1 && (
                          <Box className={classes.dateContainer}>
                            <H3 className={classes.startDate}>
                              {isComingSoonWebinar ? (
                                label
                              ) : (
                                <>
                                  <Icon>Calendar</Icon>
                                  {webinarLaunchTime}
                                </>
                              )}
                            </H3>
                          </Box>
                        )}
                    </div>

                    <div className={classes.columnLeft}>
                      {subtitle && (
                        <H3 className={classes.subtitle}>{subtitle}</H3>
                      )}
                      {!!numberedWebinarSeriesEvents && renderSummaryLinks()}
                      {description && (
                        <Box className={classes.description}>
                          <MarkdownHandler>{description}</MarkdownHandler>
                        </Box>
                      )}
                      {webinarSeriesEvents?.length > 0 &&
                        renderBloks(numberedWebinarSeriesEvents)}
                      {!!preBody &&
                        (markdownDescription ? (
                          <TextSm className={classes.markDownText}>
                            <MarkdownHandler>
                              {markdownDescription}
                            </MarkdownHandler>
                          </TextSm>
                        ) : (
                          !isVideoModule &&
                          renderBloks(preBody, {
                            removeContainer: true,
                          })
                        ))}
                    </div>

                    <div
                      className={classNames(
                        classes.columnRight,
                        classes.formContainer
                      )}
                    >
                      {webinarVideoExists && !assetIsUngated && !isFormVisible && (
                        <Box>
                          <Button
                            size={'large'}
                            color={'primary'}
                            className={classes.buttonLink}
                            onClick={() => setFormVisibility(true)}
                          >
                            {watchNowString.toUpperCase()}
                          </Button>
                        </Box>
                      )}
                      {(assetIsUngated ||
                        (!shouldShowVideo &&
                          isFormVisible &&
                          !!loadMarketoForm)) && (
                        <Box
                          className={classNames({
                            [classes.formSticky]: isStickyForm,
                          })}
                        >
                          <EventTimer startDate={startDate} />
                          {assetGraphic && (
                            <Box className={classes.infoGraphicImageContainer}>
                              <img
                                src={assetGraphic}
                                alt={imageAltText}
                                className={classes.infographicImage}
                              />
                            </Box>
                          )}
                          {!!customForm && customForm.length > 0
                            ? renderBloks(customForm, {
                                assetToDownload,
                                buttonAssetUrl,
                                hiddenFields: hiddenFieldsForForm,
                                shouldHideFormSubmissionStatus: true,
                                isWebinarsModule,
                                wistiaRef,
                                setFormSubmission,
                                isUngated: assetIsUngated,
                              })
                            : marketoForm
                            ? renderBloks([marketoForm], {
                                assetToDownload,
                                buttonAssetUrl,
                                setFormSubmission,
                                //deploy-preview-1036--accweb.netlify.app/editor/?path=us/products/product-roadmap
                                https: variant,
                                isWebinarsModule,
                                isOnDemandButtonVisible,
                                submitButtonText,
                                wistiaRef,
                                isUngated: assetIsUngated,
                              })
                            : renderBloks([resourceForm], {
                                assetToDownload,
                                buttonAssetUrl,
                                hiddenFields: hiddenFieldsForForm,
                                setFormSubmission,
                                variant,
                                isWebinarsModule,
                                isOnDemandButtonVisible,
                                submitButtonText,
                                wistiaRef,
                                isUngated: assetIsUngated,
                              })}
                        </Box>
                      )}

                      {handleDesktop && !isWebinarSeries && (
                        <VideoPreview
                          isWebinarSeries={isWebinarSeries}
                          assetGraphic={assetGraphic}
                          classes={classes}
                          imageAltText={imageAltText}
                          webinarVideoExists={webinarVideoExists}
                          videoPreviewTitle={videoPreviewTitle}
                          webinarPreviewBlok={webinarPreviewBlok}
                          setWistiaRef={setWistiaRef}
                        />
                      )}
                    </div>

                    {!handleDesktop && !isWebinarSeries && (
                      <VideoPreview
                        isWebinarSeries={isWebinarSeries}
                        assetGraphic={assetGraphic}
                        classes={classes}
                        imageAltText={imageAltText}
                        webinarVideoExists={webinarVideoExists}
                        videoPreviewTitle={videoPreviewTitle}
                        webinarPreviewBlok={webinarPreviewBlok}
                        setWistiaRef={setWistiaRef}
                      />
                    )}

                    {isWebinarSeries && (
                      <VideoPreview
                        isWebinarSeries={isWebinarSeries}
                        assetGraphic={assetGraphic}
                        classes={classes}
                        imageAltText={imageAltText}
                        webinarVideoExists={webinarVideoExists}
                        videoPreviewTitle={videoPreviewTitle}
                        webinarPreviewBlok={webinarPreviewBlok}
                        setWistiaRef={setWistiaRef}
                      />
                    )}
                  </div>
                  {/* END COLUMNS */}
                  {speakerCards?.length > 0 && (
                    <Box>
                      <H3 className={classes.speakersTitle}>Speakers</H3>
                      <Box className={classes.speakers}>
                        {renderBloks(speakerCards)}
                      </Box>
                    </Box>
                  )}
                  {!!isVideoModule && preBody && renderBloks(preBody)}
                </ContentContainer>
              </PageContainer>
              {!!body && body.length > 0 && renderBloks(body)}
            </Box>
          </Box>
        </SbEditable>
      </ThemeProvider>
    </Layout>
  )
}

export default ResourceDetail
